<template>
    <filter-popover :value="value"
                    label-position="left"
                    :fields="fields"
                    :count="count"
                    :disabled="disabled"
                    with-organization
                    @change="change"
                    @clear="clear"
                    @confirm="confirm" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// TODO@deprecated
import * as datetimeUtils from '@/utils/datetime'

import { types, kinds, memberSubRoles, translateKind } from '@/models/tasks'

import FilterPopover from '@/components/popovers/FilterPopover'

export default {
  components: {
    FilterPopover
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Object, default: null },
    type: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    deprecated: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({ statuses: 'dirs/taskStatusesOptions' }),

    isDefectsType() { return this.type === types.DEFECTS_AND_VIOLATIONS },
    isBuildingOrderType() { return this.type === types.BUILDING_ORDER },
    isPhotoOrderType() { return this.type === types.PHOTO_ORDER },
    isAcceptanceType() { return this.type === types.ACCEPTANCE_OF_WORK },
    isApprovalType() { return this.type === types.APPROVAL_PROCESS },

    fields() {
      return [
        {
          type: 'input',
          propName: 'name',
          placeholder: 'Название',
          label: 'Название',
          group: 'Основное'
        }, 
        {
          type: 'input',
          propName: 'number_in_project',
          label: 'Номер в проекте',
          placeholder: 'Номер в проекте',
          group: 'Основное'
        },
        {
          type: 'multiselect',
          propName: 'status',
          label: 'Статус',
          options: this.statuses,
          group: 'Основное'
        },
        ...this.isDefectsType && [{
          type: 'select',
          propName: 'kind_defect',
          label: 'Тип',
          options: Object.values(kinds).map(value => ({
            value,
            label: translateKind(value)
          })),
          group: 'Основное'
        }] || [],
        ...(this.isDefectsType || this.isAcceptanceType) && [{
          type: 'place',
          propName: 'place',
          group: 'Проект'
        }] || [],
        {
          type: 'user',
          placeholder: 'Инициатор',
          label: 'Инициатор',
          group: 'Пользователи',
          props: {
            isTaskCreator: true,
            withOrganizations: true,
            withDisabledSelection: true
          },
          from: value => value['creator'] || value['creatorOrganization'],
          to: value => ({
            'creator': value?._isUser && value,
            'creatorOrganization': value?._isOrganization && value
          }) 
        },
        ...this.isAcceptanceType && [{
          type: 'user',
          propName: 'acceptanceOfWorkContractor.id',
          placeholder: 'Подрядчик',
          label: 'Подрядчик',
          group: 'Пользователи',
          props: {
            isContractor: true,
            withOrganizations: true,
            withDisabledSelection: true
          },
          from: value => value['contractor'] || value['contractorOrganization'],
          to: value => ({
            'contractor': value?._isUser && value,
            'contractorOrganization': value?._isOrganization && value
          })
        }] || [],
        ...(this.isDefectsType || this.isBuildingOrderType) && [{
          type: 'user',
          propName: 'memberWorkers.user.id',
          placeholder: 'Подрядчик',
          label: 'Подрядчик',
          group: 'Пользователи',
          props: {
            isContractor: true,
            withOrganizations: true,
            withDisabledSelection: true
          },
          from: value => value['worker'] || value['workerOrganization'],
          to: value => ({
            'worker': value?._isUser && value,
            'workerOrganization': value?._isOrganization && value
          })
        }] || [],
        ...this.isDefectsType && [{
          type: 'user',
          propName: 'defectGeneralContractor.id',
          placeholder: 'Контроль',
          label: 'Контроль',
          group: 'Пользователи',
          props: {
            isInspector: true,
            withOrganizations: true,
            withDisabledSelection: true
          },
          from: value => value['inspector'] || value['inspectorOrganization'],
          to: value => ({
            'inspector': value?._isUser && value,
            'inspectorOrganization': value?._isOrganization && value
          })
        }] || [],
        ...this.isAcceptanceType && [{
          type: 'user',
          propName: 'memberWorkers.user.id',
          placeholder: 'Инженер стройконтроля от генподрядчика',
          label: 'Инженер стройконтроля от генподрядчика',
          group: 'Пользователи',
          props: {
            isEngineer: true,
            withOrganizations: true,
            withDisabledSelection: true
          },
          from: x => (x['memberWorkers.sub_role'] === memberSubRoles.WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_FIRST_STAGE || null) && (x['worker'] || x['workerOrganization']),
          to: value => ({
            'worker': value?._isUser && value,
            'workerOrganization': value?._isOrganization && value,
            'memberWorkers.sub_role': value && memberSubRoles.WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_FIRST_STAGE
          })
        }] || [],
        ...this.isAcceptanceType && [{
          type: 'user',
          propName: 'memberWorkers.user.id',
          placeholder: 'Инженер стройконтроля от заказчика',
          label: 'Инженер стройконтроля от заказчика',
          group: 'Пользователи',
          props: {
            isGeneralEngineer: true,
            withOrganizations: true,
            withDisabledSelection: true
          },
          from: x => (x['memberWorkers.sub_role'] === memberSubRoles.WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_SECOND_STAGE || null) && (x['worker'] || x['workerOrganization']),
          to: value => ({
            'worker': value?._isUser && value,
            'workerOrganization': value?._isOrganization && value,
            'memberWorkers.sub_role': value && memberSubRoles.WORKER_ACCEPTANCE_TASK_CONTROL_ENGINEER_SECOND_STAGE
          })
        }] || [],
        ...this.isPhotoOrderType && [{
          type: 'user',
          propName: 'inspector',
          placeholder: 'Ответственный',
          label: 'Ответственный',
          group: 'Пользователи',
          props: {
            withOrganizations: true,
            withDisabledSelection: true
          },
          from: value => value['inspector'] || value['inspectorOrganization'],
          to: value => ({
            'inspector': value?._isUser && value,
            'inspectorOrganization': value?._isOrganization && value
          })
        }] || [],
        ...this.isDefectsType && [{
          type: 'input',
          propName: 'buildingOrderName',
          placeholder: 'Предписание',
          label: 'Предписание',
          group: 'Проект'
        }] || [],
        {
          type: 'date',
          propName: 'created_from',
          label: 'Создан с',
          group: 'Даты'
        },
        {
          type: 'date',
          propName: 'created_to',
          label: 'Создан до',
          group: 'Даты'
        },
        ...this.type && !this.isAcceptanceType && !this.isApprovalType && [{
          type: 'date',
          propName: 'expired_from',
          label: 'Истекает с',
          group: 'Даты'
        }] || [],
        ...this.type && !this.isAcceptanceType && !this.isApprovalType && [{
          type: 'date',
          propName: 'expired_to',
          label: 'Истекает до',
          group: 'Даты'
        }] || [],
        ...this.isAcceptanceType & [{
          type: 'date',
          propName: 'started_from',
          label: 'Дата приемки с',
          group: 'Даты'
        }] || [],
        ...this.isAcceptanceType & [{
          type: 'date',
          propName: 'started_to',
          label: 'Дата приемки до',
          group: 'Даты'
        }] || [],
        {
          type: 'switch',
          propName: 'with_trashed',
          label: 'Показать удаленные',
          group: 'Прочее'
        }
      ]
    },

    count() {
      return Object.entries(this.value || {})
        .filter(([_, v]) => v)
        .filter(([_, v]) => Array.isArray(v) ? v.length : true)
        .filter(([k, _]) => ![
          'type', 
          'memberWorkers.sub_role'
        ].includes(k))
        .length
    }
  },
  mounted() {
    !this.statuses.length && this.getTaskStatuses()
  },
  methods: {
    ...mapActions('dirs', [
      'getTaskStatuses'
    ]),

    change(x) {
      this.$emit('change', x)
    },

    clear() {
      this.$emit('clear')
    },

    confirm() {
      this.$emit('confirm', [this.value, this.serialize()])
    },

    serialize() {
      const filter = { ...this.value }

      const deprecatedKeys = {
        initiatorUserId: 'creator.id',
        initiatorOrganizationId: 'creator_organization_id',
        inspectorUserId: 'defectGeneralContractor.id',
        inspectorOrganizationId: 'defect_general_contractor_organization_id',
        workerUserId: 'memberWorkers.user.id',
        workerOrganizationId: 'worker_organization_id',
        acceptanceContractorUserId: 'acceptanceOfWorkContractor.id',
        acceptanceContractorOrganizationId: 'acceptance_work_contractor_organization_id',
        buildingOrderName: 'objectsBuildingOrderConnection.object.name'
      }

      const keys = this.deprecated ? deprecatedKeys : {
        initiatorUserId: 'initiator_user_id',
        initiatorOrganizationId: 'initiator_organization_id',
        inspectorUserId: 'inspector_user_id',
        inspectorOrganizationId: 'inspector_organization_id',
        workerUserId: 'worker_user_id',
        workerOrganizationId: 'worker_organization_id',
        acceptanceContractorUserId: 'acceptance_work_contractor_user_id',
        acceptanceContractorOrganizationId: 'acceptance_work_contractor_organization_id',
        buildingOrderName: 'connected_building_order_task_name'
      }

      filter.creator && (filter[keys.initiatorUserId] = filter.creator.id)
      filter.creatorOrganization && (filter[keys.initiatorOrganizationId] = filter.creatorOrganization.id)

      filter.creator = null
      filter.creatorOrganization = null

      is({
        [types.DEFECTS_AND_VIOLATIONS]: () => {
          filter.worker && (filter[keys.workerUserId] = filter.worker.id)
          filter.workerOrganization && (filter[keys.workerOrganizationId] = filter.workerOrganization.id)

          filter.worker = null
          filter.workerOrganization = null

          filter.inspector && (filter[keys.inspectorUserId] = filter.inspector.id)
          filter.inspectorOrganization && (filter[keys.inspectorOrganizationId] = filter.inspectorOrganization.id)

          filter.inspector = null
          filter.inspectorOrganization = null

          filter[keys.buildingOrderName] = filter.buildingOrderName
          
          filter.buildingOrderName = null
        },
        [types.ACCEPTANCE_OF_WORK]: () => {
          filter.contractor && (filter[keys.acceptanceContractorUserId] = filter.contractor.id)
          filter.contractorOrganization && (filter[keys.acceptanceContractorOrganizationId] = filter.contractorOrganization.id)

          filter.contractor = null
          filter.contractorOrganization = null

          filter.worker && (filter[keys.workerUserId] = filter.worker.id)
          filter.workerOrganization && (filter[keys.workerOrganizationId] = filter.workerOrganization.id)

          filter.worker = null
          filter.workerOrganization = null
        },
        [types.BUILDING_ORDER]: () => {
          filter.worker && (filter[keys.workerUserId] = filter.worker.id)
          filter.workerOrganization && (filter[keys.workerOrganizationId] = filter.workerOrganization.id)

          filter.worker = null
          filter.workerOrganization = null
        },
        [types.PHOTO_ORDER]: () => {
          filter.inspector && (filter[keys.initiatorUserId] = filter.inspector.id)
          filter.inspectorOrganization && (filter[keys.inspectorOrganizationId] = filter.inspectorOrganization.id)

          filter.inspector = null
          filter.inspectorOrganization = null
        }
      })[this.type]?.()

      filter.expired_from && (filter.expired_from = datetimeUtils.dateFromInServerTimezoneFormat(filter.expired_from))
      filter.expired_to && (filter.expired_to = datetimeUtils.dateToInServerTimezoneFormat(filter.expired_to))
      filter.created_from && (filter.created_from =  datetimeUtils.dateFromInServerTimezoneFormat(filter.created_from))
      filter.created_to && (filter.created_to = datetimeUtils.dateFromInServerTimezoneFormat(filter.created_to))
      filter.started_from && (filter.started_from =  datetimeUtils.dateFromInServerTimezoneFormat(filter.started_from))
      filter.started_to && (filter.started_to = datetimeUtils.dateFromInServerTimezoneFormat(filter.started_to))

      filter.place?.house && !filter.place?.floor && (filter.house = filter.place.house.id)
      filter.place?.floor && (filter.floor = filter.place.floor.id)

      filter.place = null

      const result = Object.entries(filter).reduce((r, [k, v]) => ({
        ...r,
        ...v && { [k]: v }
      }), {})

      return result
    }
  }
}
</script>
